/*
*      <div class="xa-cell__box xa-bg-white">
        <van-uploader :after-read="uploadMix_afterRead" v-model="images" />
      </div>
*/
import request from '@/controllers/request'
import { Uploader } from 'vant'
import {
  getQiniuUploadCfg,
  getUploadToken,
  PostQiniuKey2Guid
} from '@/apis/resource'
export default {
  components: {
    VanUploader: Uploader
  },
  methods: {
    async uploadMix_upload() {
      const { uptoken } = await this.$_request(getUploadToken())
      return uptoken
    },
    async uploadMultipleFile(files) {
      const toast = this.$toast.loading({
        message: '上传中...',
        forbidClick: false,
        duration: 0
      })
      try {
        for (let i = 0; i < files.length; i++) {
          await this._uploadFile(files[i], i, toast)
        }
        toast.close()
      } catch (error) {
        toast.close()
        this.$dialog.alert({
          message: error.message || error.msg || '上传异常'
        })
        return Promise.resolve(-1)
      }
      return Promise.resolve(1)
    },
    async _uploadFile(file, index, toast) {
      file.status = 'uploading'
      file.message = '上传中...'
      try {
        const token = await this.uploadMix_upload()
        const result = await request(
          getQiniuUploadCfg(
            {
              file: file.file,
              token
            },
            progressEvent => {
              toast.message = `正在上传第${index + 1}张，${Math.floor(
                (100 * progressEvent.loaded) / progressEvent.total
              )}%`
            }
          )
        )
        const qiniuResult = await this.$_request(PostQiniuKey2Guid(result.key))
        file.guid = qiniuResult[result.key]
        file.status = 200
        toast.message = '上传成功'
      } catch (error) {
        file.status = 'failed'
        file.status = 400
        file.message = error.message || error.msg || '上传异常'
      }
    },
    async uploadMix_afterRead(file) {
      if (Array.isArray(file)) return this.uploadMultipleFile(file)
      const toast = this.$toast.loading({
        message: '上传中...',
        forbidClick: false,
        duration: 0
      })
      file.status = 'uploading'
      file.message = '上传中...'
      try {
        const token = await this.uploadMix_upload()
        const result = await request(
          getQiniuUploadCfg(
            {
              file: file.file,
              token
            },
            progressEvent => {
              toast.message = `已上传${Math.floor(
                (100 * progressEvent.loaded) / progressEvent.total
              )}%`
            }
          )
        )
        const qiniuResult = await this.$_request(PostQiniuKey2Guid(result.key))
        file.guid = qiniuResult[result.key]
        setTimeout(() => {
          file.status = 200
          toast.message = '上传成功'
          toast.close()
        }, 1000)
      } catch (error) {
        toast.close()
        this.$dialog.alert({
          message: error.message || error.msg || '上传异常'
        })
        return Promise.resolve(-1)
      }
      return Promise.resolve(1)
    }
  }
}
